@import 'libs/styles/src/common/common';

$headshot-size: 80px;
$large-headshot-size: 140px;
$small-headshot-size: 64px;
$micro-headshot-size: 40px; // original small size

@layer shared-component {
  .hs-image {
    min-width: 0;

    // float: left;
    text-align: left;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;

    img,
    svg {
      object-fit: cover;
      max-width: unset;
      width: 100%;
      height: 100%;
    }

    svg {
      padding-top: 0.5rem;
    }

    picture {
      width: 100%;
      height: 100%;
    }
  }

  .headshot {
    background: $header-light-blue center center no-repeat;
    border-radius: 50%;
    position: relative;
    margin: 0;

    // overflow: hidden;
    &.no-background {
      background: unset;
    }

    .medifind-score {
      position: absolute;
      top: -9px;
      right: -9px;
      bottom: -9px;
      left: -9px;
      z-index: 2;

      svg {
        width: $headshot-size + 18;
        height: $headshot-size + 18;
      }
    }

    &.v1-card__headshot {
      .medifind-score {
        .hide-mobile {
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        .hide-desktop {
          @include media-breakpoint-up(md) {
            display: none;
          }
        }
      }
    }

    .round-outline {
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 100%;

      &::after {
        content: '';
        position: absolute;
        top: -8px;
        left: -8px;
        right: -8px;
        bottom: -8px;
        border: 2px solid #ebebeb;
        border-radius: 50%;
      }
    }

    &.large {
      width: $large-headshot-size;
      height: $large-headshot-size;
      margin-bottom: 1.5rem;

      @include media-breakpoint-down(sm) {
        height: $small-headshot-size;
        width: $small-headshot-size;
      }

      .hs-image {
        height: $large-headshot-size;

        @include media-breakpoint-down(sm) {
          height: $small-headshot-size;
        }

        img,
        svg {
          min-height: $large-headshot-size;
          width: $large-headshot-size;

          @include media-breakpoint-down(sm) {
            min-height: $small-headshot-size;
            width: $small-headshot-size;
          }
        }
      }

      .medifind-score {
        svg {
          width: $large-headshot-size + 18;
          height: $large-headshot-size + 18;

          @include media-breakpoint-down(sm) {
            height: $small-headshot-size + 18;
            width: $small-headshot-size + 18;
          }
        }
      }
    }

    &.medium {
      width: $headshot-size;
      height: $headshot-size;

      .hs-image {
        height: $headshot-size;

        img,
        svg {
          min-height: $headshot-size;
          width: $headshot-size;
        }
      }
    }

    &.v2-card__headshot {
      width: $headshot-size;
      height: $headshot-size;

      // margin-bottom: 10px;

      // @include media-breakpoint-down(md) {
      //   margin-bottom: 0;
      // }

      .medifind-score {
        // styling for thicker CardV2 tier outline on mobile

        .hide-mobile {
          @include media-breakpoint-down(xs) {
            display: none;
          }
        }

        .hide-desktop {
          width: 82px;
          height: 82px;

          @include media-breakpoint-up(sm) {
            display: none;
          }
        }
      }

      @include media-breakpoint-down(xs) {
        height: 64px;
        width: 64px;
      }

      .hs-image {
        height: $headshot-size;

        @include media-breakpoint-down(xs) {
          height: 64px;
        }

        img,
        svg {
          min-height: $headshot-size;
          width: $headshot-size;

          @include media-breakpoint-down(xs) {
            min-height: 64px;
            width: 64px;
          }
        }
      }
    }

    &.small {
      width: $small-headshot-size;
      height: $small-headshot-size;

      .round-outline {
        &::after {
          display: none;
        }
      }

      .hs-image {
        height: $small-headshot-size;

        img,
        svg {
          min-height: $small-headshot-size;
          width: $small-headshot-size;
          object-fit: cover;
        }
      }
    }

    &.micro {
      width: $micro-headshot-size;
      height: $micro-headshot-size;

      .round-outline {
        &::after {
          display: none;
        }
      }

      .hs-image {
        height: $micro-headshot-size;

        img,
        svg {
          min-height: $micro-headshot-size;
          width: $micro-headshot-size;
          object-fit: cover;
        }
      }
    }

    &.score-margin {
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  // 0-24 "experienced"
  .tier-1 {
    // quarter-circle requires some manipulation since the actual drawn SVG is smaller in footprint
    svg.tier-radial-circle {
      transform: scale(0.5) translateX(-50%) translateY(50%);
    }

    &--label::after {
      content: 'Experienced';
      color: $tier-1;
    }
  }

  // 25-49 "advanced"
  .tier-2 {
    // semi-circle requires some manipulation since the actual drawn SVG is smaller in footprint
    svg.tier-radial-circle {
      transform: translateX(-25%);
    }

    &--label::after {
      content: 'Advanced';
      color: $tier-2;
    }
  }

  // 50-74 "distinguished"
  .tier-3--label::after {
    content: 'Distinguished';
    color: $tier-3;
  }

  // 75+ "elite"
  .tier-4--label::after {
    content: 'Elite';
    color: $tier-4;
  }

  .medifind-score::after {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 100px);
    text-align: center;
    height: 24px;
    margin-top: 0.5em;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.5px;
    text-wrap: nowrap;

    @include media-breakpoint-down(xs) {
      transform: translate(-50%, 80px);
      text-transform: none;
      letter-spacing: normal;
      font-size: 14px;
    }
  }
}
