@import 'libs/styles/src/common/common';

@layer component {
  .filter {
    width: 100%;
    margin-bottom: 0.5rem;

    .focus-container {
      display: inline-block;
    }

    &:focus > .focus-container {
      outline: auto 5px -webkit-focus-ring-color;
    }

    .header {
      display: flex;
      min-height: 24px;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      @include media-breakpoint-up(lg) {
        justify-content: flex-start;
      }
    }

    .clear-button {
      font-size: 14px;
      color: $blue;
      font-weight: 700;
      letter-spacing: initial;
      padding-right: 0;

      &--disabled {
        color: $disabled-grey;
        font-weight: 600;
      }
    }

    .label {
      font-weight: 700;
      font-size: 16px;
      color: $dark-blue;
    }

    .action {
      align-self: center;

      // margin-bottom: 6px;
      margin-left: 0.5rem;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: initial;
    }

    .placeholder {
      font-size: 1rem;
      font-weight: bold;

      > div {
        position: relative;

        // margin-right: 1.5rem;
        padding-right: 14px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        // width: 100%;
        max-width: 100%;
        cursor: pointer;

        &::after {
          content: '';
          border-right: 2px solid $blue;
          border-top: 2px solid $blue;
          transform: rotate(45deg) translateY(-50%);
          display: block;
          width: 6px;
          height: 6px;
          right: 6px;
          position: absolute;
          top: 50%;
          margin-top: -2px;
        }
      }
    }

    &--xs {
      @include media-breakpoint-down(xs) {
        cursor: pointer;

        .children {
          display: none;
        }

        .label {
          color: $dark-blue;
        }
      }

      @include media-breakpoint-up(sm) {
        .placeholder {
          display: none;
        }
      }
    }

    &--sm {
      @include media-breakpoint-down(sm) {
        cursor: pointer;

        .children {
          display: none;
        }

        .label {
          color: $dark-blue;
        }
      }

      @include media-breakpoint-up(md) {
        .placeholder {
          display: none;
        }
      }
    }

    &--md {
      @include media-breakpoint-down(md) {
        cursor: pointer;

        .children {
          display: none;
        }

        .label {
          color: $dark-blue;
        }
      }

      @include media-breakpoint-up(lg) {
        .placeholder {
          display: none;
        }
      }
    }

    &--lg {
      @include media-breakpoint-down(lg) {
        cursor: pointer;

        .children {
          display: none;
        }

        .label {
          color: $dark-blue;
        }
      }

      @include media-breakpoint-up(xl) {
        .placeholder {
          display: none;
        }
      }
    }

    &--xl {
      @include media-breakpoint-down(xl) {
        cursor: pointer;

        .children {
          display: none;
        }

        .label {
          color: $dark-blue;
        }
      }

      @include media-breakpoint-up(xxl) {
        .placeholder {
          display: none;
        }
      }
    }

    &--xxl {
      @include media-breakpoint-down(xxl) {
        cursor: pointer;

        .children {
          display: none;
        }

        .label {
          color: $blue;
        }
      }
    }
  }

  .result-filter-list__container {
    max-width: none;
    margin: unset;
    width: initial;

    :global(.row) {
      padding: 1rem 1rem 0.5rem;

      &:not(:first-child) {
        border-top: 1px solid $light-disabled-grey;
      }
    }
  }

  .filter-menu-wrapper,
  .condition-search__menu-wrapper,
  .specialty-search__menu-wrapper {
    box-shadow: none;
    background-color: transparent;
  }

  .filter-input-wrapper {
    background-color: #fff;
  }

  .condition-search__menu-wrapper,
  .specialty-search__menu-wrapper {
    min-width: unset !important;
  }

  .select-group-column {
    display: flex;
    flex-direction: column;

    &__wrapper {
      font-size: 0.9rem;
    }
  }

  .filter {
    &__wrapper {
      width: 100%;
    }
  }

  .icon {
    display: inline-block;
    padding: 0 0 2px 1rem;
  }

  .input-container-override {
    height: unset !important;
    color: $blue-alt-1 !important;
    border: 1px solid $light-disabled-grey;

    .input-override {
      font-weight: 600 !important;
      font-size: 16px !important;
    }
  }

  .full-width {
    display: flex;
  }

  .error {
    user-select: none;
    position: relative;
    top: 0.25rem;
    left: 1.25rem;
    height: 0;
    color: $red;
    font-size: 13px;
  }

  %error-message {
    color: #e5243e;
    user-select: none;
    padding-left: 1.25rem;
    height: 0;
    margin-top: 4px;
    margin-bottom: 5px;
    font-size: 14px;
  }

  .error-message {
    @extend %error-message;

    &__filter {
      @extend %error-message;

      height: fit-content;
      padding-left: 0;
      margin-bottom: 0;
    }
  }

  .autocomplete-error-message {
    color: #e5243e;
  }
}
